import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { motion, AnimatePresence } from "framer-motion";
import "./App.css";

const App = () => {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const BACKEND_API = process.env.REACT_APP_BACKEND_API;

  const fetchSlides = useCallback(async () => {
    try {
      const response = await axios.get(`${BACKEND_API}/public/slides`);
      if (response.data.status === "success") {
        setSlides(response.data.payload);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching slides", error);
    }
  }, [BACKEND_API]);

  useEffect(() => {
    fetchSlides();
  }, [fetchSlides]);

  useEffect(() => {
    if (!isLoading && slides.length > 0) {
      const slideDuration =
        slides[currentIndex].slide_title === "Leaderboard" ? 7000 : 4000;

      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % slides.length;
          if (nextIndex === 0) {
            fetchSlides();
          }
          return nextIndex;
        });
      }, slideDuration);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, slides, isLoading, fetchSlides]);

  if (isLoading) return <div>Loading...</div>;

  const currentSlide = slides[currentIndex];

  return (
    <div className="presentation">
      {/* Static Header */}
      <h2 className="slide-title">{currentSlide.slide_title}</h2>

      <AnimatePresence mode="wait">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{ duration: 1 }}
        >
          {currentSlide.slide_title === "Leaderboard" ? (
            <LeaderboardSlide slide={currentSlide} />
          ) : (
            <PictureSlide slide={currentSlide} backendApi={BACKEND_API} />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const LeaderboardSlide = ({ slide }) => {
  return (
    <div className="slide leaderboard">
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Telegram Login</th>
            <th>Points</th>
          </tr>
        </thead>
        <tbody>
          {slide.row.slice(0, 10).map((row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{row.telegram_login}</td>
              <td>{row.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PictureSlide = ({ slide, backendApi }) => {
  const { picture_author, picture_url } = slide.row;
  return (
    <div className="slide picture-slide">
      <img
        src={`${backendApi}/public/file/${picture_url}`}
        alt={picture_author}
      />
      <p>Author: {picture_author}</p>
    </div>
  );
};

export default App;
